.round-1 {
  border-radius: 5px !important; }

.round-2 {
  border-radius: 10px !important; }

.round-3 {
  border-radius: 15px !important; }

.round-4 {
  border-radius: 20px !important; }

.round-5 {
  border-radius: 25px !important; }
