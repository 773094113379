html {
  font-weight: normal; }

html,
body {
  height: var(--vph); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-weight: 400;
  line-height: 1.3; }

p {
  font-family: Montserrat !important;
  font-weight: normal; }

h1 {
  font-size: 4.2rem;
  line-height: 110%;
  margin: 2.8rem 0 1.68rem 0;
  color: var(--default-primary) !important; }

h2 {
  font-size: 3.56rem;
  line-height: 110%;
  margin: 2.3733333333rem 0 1.424rem 0;
  color: var(--accent) !important; }

h3 {
  font-size: 2.92rem;
  line-height: 110%;
  margin: 1.9466666667rem 0 1.168rem 0;
  color: var(--dark-primary) !important; }

h4 {
  font-size: 2.28rem;
  line-height: 110%;
  margin: 1.52rem 0 0.912rem 0;
  color: var(--secondary-text) !important; }

h5 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 1.0933333333rem 0 0.656rem 0;
  color: var(--primary-text) !important; }

h6 {
  font-size: 1.15rem;
  line-height: 110%;
  margin: 0.7666666667rem 0 0.46rem 0;
  color: var(--primary-text) !important; }

code {
  color: var(--primary-text) !important;
  background: var(--light-primary) !important;
  border-radius: 5px !important;
  padding: 3px; }

h1.mnext {
  text-shadow: 10px -15px 20px rgba(0, 0, 0, 0.4), 8px -12px 15px rgba(0, 0, 0, 0.6), 5px -8px 10px rgba(0, 0, 0, 0.8), 2px -3px 0px black, 1px -2px 0px black;
  font-weight: 600; }

h2.mnext {
  text-shadow: 8px -12px 15px rgba(0, 0, 0, 0.4), 6px -9px 15px rgba(0, 0, 0, 0.6), 2px -3px 7px rgba(0, 0, 0, 0.8), 1px -2px 0px black, 1px -1px 0px black;
  font-weight: 600; }

h3.mnext {
  text-shadow: 8px -12px 15px rgba(0, 0, 0, 0.4), 6px -9px 15px rgba(0, 0, 0, 0.6), 2px -5px 7px rgba(0, 0, 0, 0.8), 1px -2px 0px black, 1px -1px 0px black;
  font-weight: 600; }

h4.mnext {
  text-shadow: 7px -10px 15px rgba(0, 0, 0, 0.4), 4px -6px 9px rgba(0, 0, 0, 0.6), 2px -4px 7px rgba(0, 0, 0, 0.8), 2px -3px 0px black, 1px -2px 0px black;
  font-weight: 600; }

h5.mnext {
  text-shadow: 7px -10px 15px rgba(0, 0, 0, 0.6), 2px -4px 7px rgba(0, 0, 0, 0.6), 1px -1px 0px black, 1px -1px 0px black;
  font-weight: 600; }

h6.mnext {
  text-shadow: 1px -5px 6px rgba(0, 0, 0, 0.2), 2px -4px 7px rgba(0, 0, 0, 0.4), 1px -1px 0px;
  font-weight: 600; }
