.btn-floating.btn-large {
  background-color: var(--accent) !important; }

.headingShadow {
  text-shadow: 10px -15px 20px rgba(0, 0, 0, 0.4), 8px -12px 15px rgba(0, 0, 0, 0.6), 5px -8px 10px rgba(0, 0, 0, 0.8), 2px -3px 0px black, 1px -2px 0px black;
  font-weight: 600; }

.page-footer {
  background-color: var(--default-primary) !important; }

.footer-copyright {
  background-color: var(--dark-primary) !important; }
